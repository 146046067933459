import { Kitchen } from "@prmf/site-data";
import { routes } from "@prmf/site-router";
import { Link, useFetcher } from "@remix-run/react";
import { useEffect, useRef } from "react";
import { twJoin, twMerge } from "tailwind-merge";
import BgImage from "./BgImage";
import InView from "./InView";

type Props = {
  title?: string;
  className?: string;
};

function foo(ele: HTMLElement) {
  let pos = { top: 0, left: 0, x: 0, y: 0 };

  //function mouseIsDown(e: MouseEvent) {
  //  isDown = true;
  //  startX = e.pageX - ele.offsetLeft;
  //  scrollLeft = ele.scrollLeft;
  //}
  const mouseDownHandler = function (e: MouseEvent) {
    ele.style.cursor = "grabbing";
    ele.style.userSelect = "none";

    pos = {
      left: ele.scrollLeft,
      top: ele.scrollTop,
      // Get the current mouse position
      //x: e.clientX,
      x: e.clientX - ele.offsetLeft,
      y: e.clientY,
    };

    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
  };

  //function mouseMove(e: MouseEvent) {
  //  if (isDown) {
  //    e.preventDefault();
  //    const x = e.pageX - ele.offsetLeft;
  //    const walkX = (x - startX) * 5;
  //    ele.scrollLeft = scrollLeft - walkX;
  //  }
  //}
  const mouseMoveHandler = function (e: MouseEvent) {
    //const dy = e.clientY - pos.y;
    //ele.scrollTop = pos.top - dy;
    //const dx = e.clientX - pos.x;
    const _dx = e.clientX - ele.offsetLeft;
    const dx = (_dx - pos.x) * 1;
    ele.scrollLeft = pos.left - dx;
  };

  const mouseUpHandler = function () {
    //ele.style.cursor = "grab";
    ele.style.removeProperty("cursor");
    ele.style.removeProperty("user-select");

    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);
  };

  ele.addEventListener("mousedown", mouseDownHandler);
  return () => ele.removeEventListener("mousedown", mouseDownHandler);
}

function useScroll(sc: React.RefObject<HTMLElement>) {
  useEffect(() => {
    if (sc.current) {
      const unsub = foo(sc.current);
      return unsub;
    }
  }, [sc]);
}

const Module: React.FC<{
  parent: React.RefObject<HTMLElement>;
  kitchens?: Kitchen[];
}> = (props) => {
  const explore = props.kitchens;
  return (
    <>
      {explore?.map((k) => (
        <InView.Self
          key={k.slug}
          threshold={0.75}
          onChange={(inView) => {
            if (inView && props.parent.current) {
              props.parent.current.style.backgroundColor = k.accentColor;
            }
          }}
          className="snap-center snap-always"
        >
          {({ inView }) => (
            <div
              onClick={(e) => {
                if (!inView) {
                  e.currentTarget.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                  });
                }
              }}
              className={twJoin(
                "space-y-4",
                "transition",
                !inView && "scale-75 cursor-pointer opacity-50",
              )}
            >
              <BgImage
                src={`/media/${k.slug}/_hero-01.jpg`}
                className={twJoin(
                  "relative overflow-hidden",
                  "min-w-[80vw]",
                  "lg:max-h-[60vh] lg:min-w-[60vw]",
                  "aspect-video w-full rounded-lg bg-stone-300",
                  inView && "shadow-lg",
                )}
              >
                {k.new && (
                  <div
                    className={twJoin(
                      "absolute left-0 top-10 px-2 py-1",
                      "bg-accent text-xs font-bold uppercase shadow",
                      "transition",
                      inView ? "delay-200 duration-300" : "-translate-x-12",
                    )}
                  >
                    New
                  </div>
                )}
                <Link
                  to={`${routes.kitchens}/${k.slug}`}
                  className={twJoin(
                    "absolute bottom-0 right-4 md:right-10",
                    "table px-4 py-2",
                    "bg-accent rounded-t-lg text-xs font-bold md:text-sm",
                    "transition",
                    "hover:bg-black hover:text-white",
                    inView ? "delay-200" : "translate-y-10",
                  )}
                >
                  View this range
                </Link>
              </BgImage>
              <div className="mx-4 flex justify-between md:mx-10">
                <div className="text-left">
                  <h4 className="font-bold">{k.name}</h4>
                  <h4 className="text-xs">
                    {k.parts.doors.length} door colours available
                  </h4>
                </div>
                <div className="flex-1 text-right">
                  <h5 className="text-xs md:text-sm">
                    Price group: {k.priceGroup}
                  </h5>
                </div>
              </div>
            </div>
          )}
        </InView.Self>
      ))}
    </>
  );
};

export default function KitchenExploreCTA(props: Props) {
  const fetcher = useFetcher<{ kitchens?: Kitchen[] }>();
  const bgRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!fetcher.data?.kitchens && fetcher.state === "idle") {
      fetcher.load("/kitchens");
    }
  }, [fetcher]);

  useScroll(listRef);

  return (
    <section
      className={twMerge(
        "relative space-y-10 pt-10 text-center",
        props.className,
      )}
    >
      <div
        ref={bgRef}
        className="absolute inset-0 bg-stone-100 opacity-50 transition duration-300"
      />
      <h2 className="relative text-2xl font-bold">
        {props.title || "Explore our kitchens"}
      </h2>
      <div
        ref={listRef}
        className={twJoin(
          "relative flex px-[10vw] pb-20 lg:px-[20vw]",
          "no-scrollbar overflow-auto scroll-smooth",
          "snap-x snap-mandatory",
        )}
      >
        <Module kitchens={fetcher.data?.kitchens} parent={bgRef} />
      </div>
    </section>
  );
}
